<!--
 * @Description: 车行道停车运营管理 运营管理 业务订单 businessOrders
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: houjinduo
-->
<template>
  <div class="mainbody">
    <el-row class="tableRow">
      <el-row class="tableContent">
        <dt-table
          :tableColumnList="tableColumnList"
          :data="tableList"
          :map="listMap"
          :tableConfig="tableConfig"
          :paginationConfig="paginationConfig"
          @getList="getList"
        >
          <template slot="operating">
            <el-table-column label="操作" class-name="class-name" width="200px">
              <template slot-scope="scope">
                <el-col :span="8">
                  <el-button
                    @click="handleClickWatch(scope.row)"
                    type="text"
                    size="medium"
                    >查看</el-button
                  >
                </el-col>
                <el-col :span="8">
                  <el-button
                    @click="handleClickUpdate(scope.row)"
                    v-show="click_update"
                    type="text"
                    size="medium"
                    >改单</el-button
                  >
                </el-col>
                <el-col :span="8">
                  <el-button
                    @click="handleClickAbnormalRemark(scope.row)"
                    v-show="abnormal_remark"
                    type="text"
                    size="medium"
                    >异常标记</el-button
                  >
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 查看弹窗 -->
    <el-dialog
      title="订单详情"
      close-on-click-modal
      :visible.sync="watchListDialog"
      :append-to-body="true"
      :destroy-on-close="true"
      width="70%"
    >
      <div style="display: flex">
        <el-row style="margin-top: 25px">
          <el-row>
            <span :span="8" class="span-title">订单编号:</span>
            <span :span="16" class="span-text">
              {{ watchFormList.orderSequence }}</span
            >
          </el-row>
          <el-row style="margin-top: 20px">
            <span style="color: white; font-size: 19px">{{
              watchFormList.plateNumber
            }}</span>
          </el-row>
          <el-row style="margin-top: 30px">
            <span :span="24" class="span-title">停车时长</span>
          </el-row>
          <el-row style="margin-top: 20px">
            <span style="font-size: 30px; color: #08f7e5"
              >{{ watchFormList.parkDurationFmt }}
            </span>
          </el-row>
          <el-row style="margin-top: 30px">
            <span :span="8" class="span-title">订单时间:</span>
            <span :span="16" class="span-text">
              {{ watchFormList.orderCreateTime }}</span
            >
          </el-row>
          <el-row style="margin-top: 10px">
            <span :span="8" class="span-title">泊位编号:</span>
            <span :span="16" class="span-text">
              {{ watchFormList.parkSpaceNumber }}</span
            >
          </el-row>
          <el-row style="margin-top: 10px">
            <span :span="8" class="span-title">订单状态:</span>
            <span :span="16" class="span-text">
              {{ watchFormList.orderStatus }}</span
            >
          </el-row>
          <el-row style="margin-top: 10px">
            <span :span="8" class="span-title">实付金额:</span>
            <span :span="16" style="color: #c29300; font-size: 16px">
              {{ watchFormList.receivedMoneyAmountFmt }}元</span
            >
          </el-row>
          <el-row style="margin-top: 10px">
            <span :span="8" class="span-title">应付金额:</span>
            <span :span="16" style="color: #c29300; font-size: 16px">
              {{ watchFormList.receivableMoneyAmountFmt }}元</span
            >
          </el-row>
          <el-row style="margin-top: 20px" v-if="watchFormList.remark">
            <span :span="8" class="span-title">备注:</span>
            <span :span="16" style="color:#C29300;font-size=16px">
              {{ watchFormList.remark }}</span
            >
          </el-row>
        </el-row>
        <!-- 右侧 -->
        <el-row
          style="
            margin-left: 30px;
            display: flex;
            margin-bottom: 10px;
            margin-top: 10px;
          "
        >
          <el-row
            style="border: 1px solid #0f91de; height: 390px; width: 240px"
          >
            <el-row
              style="
                height: 40px;
                line-height: 40px;
                display: flex;
                justify-content: space-around;
              "
            >
              <el-row style="color: #6de920">入场</el-row>
              <el-row style="color: white">{{
                watchFormList.entranceTime
              }}</el-row>
            </el-row>
            <el-row style="height: 350px">
              <el-image
                style="width: 100%; height: 100%"
                v-if="pictureIn.evidencePic"
                :src="$imgBaseUrl + pictureIn.evidencePic"
                :preview-src-list="insrcList"
                :fit="fit"
              >
                <el-row slot="error" style="height: 100%; text-align: center">
                  <el-row style="height: 100%; margin-top: 145px; color: white"
                    >该图片未上传</el-row
                  >
                </el-row>
              </el-image>
            </el-row>
          </el-row>
          <!-- 出场 -->
          <el-row
            style="
              margin-left: 20px;
              border: 1px solid rgb(15, 145, 222);
              height: 390px;
              width: 240px;
            "
          >
            <el-row
              v-if="
                watchFormList.leaveTime == '' || watchFormList.leaveTime == null
              "
              style="
                height: 40px;
                line-height: 40px;
                display: flex;
                justify-content: space-around;
              "
            ></el-row>
            <el-row
              v-else
              style="
                height: 40px;
                line-height: 40px;
                display: flex;
                justify-content: space-around;
              "
            >
              <el-row style="color: #f98d0b">出场</el-row>
              <el-row style="color: white">{{
                watchFormList.leaveTime
              }}</el-row>
            </el-row>
            <el-row style="height: 350px">
              <el-image
                style="width: 100%; height: 100%"
                v-if="pictureOut.evidencePic"
                :src="$imgBaseUrl + pictureOut.evidencePic"
                :preview-src-list="outsrcList"
                :fit="fit"
              >
                <el-row slot="error" style="height: 100%; text-align: center">
                  <el-row style="height: 100%; margin-top: 145px; color: white"
                    >该图片未上传</el-row
                  >
                </el-row>
              </el-image>
            </el-row>
          </el-row>
          <!-- 车牌最清晰 -->
          <el-row
            style="
              margin-left: 20px;
              border: 1px solid rgb(15, 145, 222);
              height: 390px;
              width: 240px;
            "
          >
            <el-row
              style="
                height: 40px;
                line-height: 40px;
                display: flex;
                justify-content: space-around;
              "
            >
              <el-row style="color: #f98d0b">车牌最清晰</el-row>
            </el-row>
            <el-row style="height: 350px">
              <el-image
                style="width: 100%; height: 100%"
                v-if="pictureClear.evidencePic"
                :src="$imgBaseUrl + pictureClear.evidencePic"
                :preview-src-list="clearsrcList"
                :fit="fit"
              >
                <el-row slot="error" style="height: 100%; text-align: center">
                  <el-row style="height: 100%; margin-top: 145px; color: white"
                    >该图片未上传</el-row
                  >
                </el-row>
              </el-image>
            </el-row>
          </el-row>
        </el-row>
        <!-- 时间线 -->
        <el-row style="width: 220px; height: 390px; margin-top: 10px">
          <el-scrollbar style="height: 100%; overflow-y: auto">
            <el-timeline>
              <el-timeline-item
                v-for="(activity, index) in activities"
                :key="index"
                :timestamp="activity.timestamp"
              >
                {{ activity.content }}
              </el-timeline-item>
            </el-timeline>
          </el-scrollbar>
        </el-row>
      </div>
    </el-dialog>
    <!-- 改单弹窗 -->
    <el-dialog
      title="车辆信息修改"
      :visible.sync="dialogFormVisible"
      append-to-body
    >
      <el-form :model="editForm" :rules="rules" ref="form">
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="车牌号"
              :label-width="formLabelWidth"
              prop="plateNumber"
            >
              <el-input v-model="editForm.plateNumber"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              prop="numberPlateColorCode"
              label="车牌颜色"
              :label-width="formLabelWidth"
            >
              <el-select
                clearable
                v-model="editForm.plateColor"
                @change="handleChangePlateColor"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in colorList"
                  :key="index"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-col :span="12">
            <el-form-item
              label="泊位号"
              :label-width="formLabelWidth"
              prop="parkSpaceNumber"
            >
              <el-input v-model="editForm.parkSpaceNumber"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-row>
          <el-button type="primary" @click="formSubmit">确 定</el-button>
        </el-row>
        <el-row style="margin-left: 17px">
          <el-button type="info" @click="dialogFormVisible = false"
            >取 消</el-button
          >
        </el-row>
      </div>
    </el-dialog>
    <!-- 异常标记 -->
    <el-dialog
      title="异常标记"
      :visible.sync="abnormalListDialog"
      append-to-body
    >
      <el-form :model="abnormalFormList" :rules="rules" ref="form">
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="备注"
              :label-width="formLabelWidth"
              prop="remark"
            >
              <el-input
                v-model="abnormalFormList.remark"
                type="textarea"
                style="width: 400px"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否离场" label-width="95px">
              <el-checkbox v-model="isLeave"></el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="abnormalLine">
          <el-col :span="12" class="itemCol">
            <span>上传图片</span>
            <span style="margin-left: 10px"
              >（注：图片大小请不要超过25MB）</span
            >
            <el-row class="itemUpload">
              <el-upload
                class="avatar-uploader"
                action=""
                accept="image/*"
                :http-request="upLoadPic"
                :before-upload="beforeAvatarUpload"
                :file-list="fileList"
                :show-file-list="false"
                :limit="1"
              >
                <i
                  class="el-icon-plus avatar-uploader-icon"
                  v-if="!abnormalFormList.pictureUrl"
                ></i>
              </el-upload>
              <div v-if="abnormalFormList.pictureUrl" style="display: flex">
                <el-image
                  class="avatar"
                  :src="$imgBaseUrl + abnormalFormList.pictureUrl"
                  @click="handleClickPicPreview"
                  :preview-src-list="abnormalPicPreview"
                >
                </el-image>
                <el-button
                  type="primary"
                  @click="handleClickDeletePic"
                  style="width: 75px; height: 32px; margin-left: 20px"
                  >删 除</el-button
                >
              </div>
            </el-row>
          </el-col>
          <el-col :span="12" class="itemCol">
            <span>上传视频</span>
            <span style="margin-left: 10px"
              >（注：视频大小请不要超过25MB。）</span
            >
            <el-row class="itemUpload">
              <el-upload
                class="avatar-uploader"
                action=""
                accept="mp4,avi,flv,mov,rmvb,x-flv"
                :http-request="upLoadVideo"
                :file-list="videofileList"
                :before-upload="beforeAvatarUploadVideo"
                :show-file-list="false"
                :limit="1"
              >
                <i
                  v-if="!showAbnormalVideo"
                  class="el-icon-plus avatar-uploader-icon"
                ></i>
              </el-upload>
              <el-row v-if="showAbnormalVideo" style="display: flex">
                <video
                  class="avatar"
                  width="100%"
                  controls="controls"
                  :src="$imgBaseUrl + abnormalFormList.videoUrl"
                  @click="handleClickVideoPreview"
                ></video>
                <el-button
                  type="primary"
                  @click="handleClickDeleteVideo"
                  style="width: 75px; height: 32px; margin-left: 20px"
                  >删 除</el-button
                >
              </el-row>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-row>
          <el-button type="primary" @click="abnormalRemarkConfirm"
            >确 定</el-button
          >
        </el-row>
        <el-row style="margin-left: 17px">
          <el-button type="info" @click="abnormalListDialog = false"
            >取 消</el-button
          >
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from "@/components/table.vue";
import formatDuration from "@/utils/duration";
import parkStopTime from "@/utils/parkStopTime";
import Compressor from "compressorjs";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { dtTable },
  props: {
    searchForm: {
      type: Object,
    },
  },
  data() {
    // 这里存放数据
    return {
      isLeave: false,
      paginationConfig: { layout: "prev,sizes,pager,next,jumper", total: 0 },
      tableConfig: { border: true },
      listMap: { pageNum: 1, pageSize: 15 },
      tableColumnList: [
        {
          prop: "orderSequence",
          label: "订单编号",
        },
        {
          prop: "plateNumber",
          label: "车牌号",
        },
        {
          prop: "plateColor",
          label: "车牌颜色",
        },
        {
          prop: "parkName",
          label: "停车场名称",
        },
        {
          prop: "parkSpaceNumber",
          label: "泊位号",
        },
        {
          prop: "parkDurationFmt",
          label: "停车时长",
        },
        {
          prop: "receivedMoneyAmountFmt",
          label: "实付金额",
        },
        {
          prop: "receivableMoneyAmountFmt",
          label: "应付金额",
        },
        {
          prop: "arrearageMoney",
          label: "欠费金额",
        },
        {
          prop: "discountMoney",
          label: "优惠金额",
        },
        {
          prop: "orderStatus",
          label: "订单状态",
        },
        {
          prop: "entranceTime",
          label: "进场时间",
        },
        {
          prop: "leaveTime",
          label: "出场时间",
        },
        {
          prop: "lastPaymentTime",
          label: "最后缴费时间",
        },
      ],
      tableList: { list: [] },
      pageNum: 1,
      pageSize: 15,

      currentTime: new Date().Format("yyyy-MM-dd hh:mm:ss"),
      colorList: [], // 车牌颜色
      fit: "fill",

      watchListDialog: false, // 查看弹窗显示
      watchFormList: {}, // 查看弹窗绑定
      abnormalListDialog: false, // 异常标记弹窗显示
      abnormalFormList: {
        orderSequence: "",
        remark: "",
        pictureUrl: "",
        videoUrl: "",
      }, // 异常标记弹窗绑定
      pictureIn: {}, //入场图片
      pictureOut: {}, //出场图片
      pictureClear: {}, //车牌最清晰图片
      insrcList: [], //进场取证图片预览
      outsrcList: [], //出场取证图片预览
      clearsrcList: [], //车牌最清晰取证图片预览

      activities: [], // 时间线
      abnormalPicPreview: [], // 异常标记图片预览
      showAbnormalVideo: false, // 视频上传显示
      fileList: [],
      videofileList: [],
      // 改单弹窗
      dialogFormVisible: false,
      formLabelWidth: "95px",
      alterColor: [],
      editForm: {}, // 改单参数
      parkSpaceNumber: "",

      //表单验证规则
      rules: {
        plateNumber: [
          { required: true, message: "请输入车牌号", trigger: "blur" },
        ],
        numberPlateColorCode: [
          { required: true, message: "请选择车牌颜色", trigger: "change" },
        ],
        parkSpaceNumber: [
          { required: true, message: "请选择泊位号", trigger: "blur" },
        ],
        vehicle_type_code: [
          { required: true, message: "请选择车辆类型", trigger: "change" },
        ],
      },
    };
  },
  mounted() {
    //菜单按钮权限
    this.buttonList = localStorage.getItem("buttonListByUser");
    this.getColorDict();
    this.queryTableList();
  },
  //方法集合
  methods: {
    // 表格查询
    queryTableList(flag = false) {
      const buttonMenuName = JSON.parse(this.buttonList);
      const menuNames = [];
      for (let i = 0; i < Object.keys(buttonMenuName).length; i++) {
        const menuName = buttonMenuName[i].menuName;
        menuNames.push(menuName);
        if (menuNames[i] === "改单") {
          this.click_update = true;
        } else if (menuNames[i] === "异常标记") {
          this.abnormal_remark = true;
        }
      }
      this.currentTime = new Date().Format("yyyy-MM-dd hh:mm:ss");
      this.searchForm["pageNum"] = this.pageNum;
      this.searchForm["pageSize"] = this.pageSize;
      this.searchForm["completed"] = flag;
      const loading = this.$loading({
        lock: true,
        text: "正在努力加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$businessOrders
        .queryOrderList(this.searchForm)
        .then((res) => {
          if (res.resultEntity) {
            loading.close();
            this.tableList.list = res.resultEntity.content;
            this.paginationConfig.total = res.resultEntity.totalElements;
            this.tableList.list.forEach((item) => {
              item.discountMoney = "0.00";
              item.arrearageMoney = Number(
                Number(item.receivableMoneyAmount - item.receivedMoneyAmount) /
                  100
              ).toFixed(2);
              item.receivedMoneyAmountFmt = Number(
                Number(item.receivedMoneyAmount) / 100
              ).toFixed(2);
              item.receivableMoneyAmountFmt = Number(
                Number(item.receivableMoneyAmount) / 100
              ).toFixed(2);
              if (!item.leaveTime) {
                item.parkDurationFmt = parkStopTime(
                  this.currentTime,
                  item.entranceTime
                );
              } else {
                item.parkDurationFmt = formatDuration(item.parkDuration);
              }
            });
          } else {
            this.tableList.list = [];
          }
        })
        .catch(() => {
          loading.close();
          this.$message.error("加载失败！");
        });
    },
    //分页
    getList(change) {
      this.pageSize = change.pageSize;
      this.pageNum = change.pageNum;
      this.queryTableList();
    },
    // 条件查询
    queryButton() {
      this.pageNum = 1;
      this.listMap.pageNum = 1;
      this.queryTableList();
    },
    // 重置
    resetForm() {
      this.pageNum = 1;
      this.listMap.pageNum = 1;
      this.searchForm = {};
      this.queryTableList();
    },
    // 提示
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isWebp = file.type === "image/webp";
      const isBmp = file.type === "image/bmp";
      const isGif = file.type === "image/gif";
      if (!isJPG && !isPNG && !isWebp && !isBmp && !isGif) {
        this.$message.error("上传图片只能是 JPG/PNG/WEBP/BMP/GIF 格式!");
        return false;
      }
      return true;
    },
    //上传图片
    upLoadPic(file) {
      const self = this;
      new Compressor(file.file, {
        quality: 0.2,
        success(result) {
          result = new window.File([result], result.name, {
            type: result.type,
          });
          const formData = new FormData();
          formData.append("file", result);
          self.$queryDict
            .upFile(formData)
            .then((res) => {
              self.fileList.push({
                name: "图片",
                url: `${self.$imgBaseUrl}${res.resultEntity}`,
              });
              self.abnormalFormList.pictureUrl = res.resultEntity;
            })
            .catch(() => {
              self.$message.error("上传失败");
            });
        },
        error() {
          self.$message.error("上传失败,请重新选择");
          self.fileList = [];
        },
      });
    },
    // 点击图片预览
    handleClickPicPreview() {
      this.abnormalPicPreview.push(
        this.$imgBaseUrl + this.abnormalFormList.pictureUrl
      );
    },
    // 点击图片删除
    handleClickDeletePic() {
      this.fileList = [];
      this.abnormalFormList.pictureUrl = "";
    },
    // 上传视频前
    beforeAvatarUploadVideo(file) {
      const ismp4 = file.type === "video/mp4";
      const isavi = file.type === "video/avi";
      const isflv = file.type === "video/flv";
      const ismov = file.type === "video/mov";
      const isrmvb = file.type === "video/rmvb";
      if (!ismp4 && !isavi && !isflv && !isrmvb && !ismov) {
        this.$message.error("上传图片只能是 MP4/AVI/FLV/MOV/RMVB格式!");
        return false;
      }
      return true;
    },
    // 视频点击
    handleClickVideoPreview() {},
    // 上传视频
    upLoadVideo(file) {
      const that = this;
      const formData = new FormData();
      formData.append("file", file.file);
      this.$queryDict
        .upFile(formData)
        .then((res) => {
          if (res.resultEntity) {
            that.abnormalFormList.videoUrl = res.resultEntity;
            that.showAbnormalVideo = true;
          }
        })
        .catch((err) => {
          that.$message.error("上传失败,请重新选择", err);
          that.videofileList = [];
        });
    },
    // 视频删除
    handleClickDeleteVideo() {
      this.showAbnormalVideo = false;
      this.videofileList = [];
      this.abnormalFormList.videoUrl = "";
    },
    // 点击异常标记
    handleClickAbnormalRemark(row) {
      this.fileList = [];
      this.videofileList = [];
      this.showAbnormalVideo = false;
      this.abnormalFormList = {
        orderSequence: "",
        remark: "",
        pictureUrl: "",
        videoUrl: "",
      };
      this.abnormalListDialog = true;
      this.abnormalFormList.orderSequence = row.orderSequence;
    },
    abolitionOrder() {
      let info = {
        orderSequence: this.abnormalFormList.orderSequence,
        remark: this.abnormalFormList.remark,
        pictureUrl: this.abnormalFormList.pictureUrl,
        videoUrl: this.abnormalFormList.videoUrl,
      };
      this.$businessOrders
        .abolitionOrder(info)
        .then(() => {
          this.$message({ message: "异常标记成功", type: "success" });
          this.abnormalListDialog = false;
          this.queryTableList();
        })
        .catch((err) => {
          this.abnormalListDialog = false;
          this.$message.error(err.resultMsg);
        });
    },
    supplementOrderForLeave(data) {
      let info = {
        orderSequence: data.orderSequence,
        plateNumber: data.plateNumber,
        numberPlateColorCode: data.numberPlateColorCode,
        leaveTime: data.entranceTime,
      };
      this.$commonAxios.supplementOrderForLeave(info).then(() => {
        this.abolitionOrder();
      });
    },
    // 异常标记确认
    abnormalRemarkConfirm() {
      if (this.isLeave) {
        this.tableList.list.forEach((item) => {
          if (item.orderSequence == this.abnormalFormList.orderSequence) {
            this.supplementOrderForLeave(item);
          }
        });
      } else {
        this.abolitionOrder();
      }
    },
    // 导出
    exportFile() {
      this.$axios
        .post(
          this.$downloadBaseUrl + "/order/downloadOrderIn",
          this.searchForm,
          {
            responseType: "blob",
            headers: {
              userToken: localStorage.getItem("userToken"),
            },
          }
        )
        .then((res) => {
          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
          let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
          // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
          let a = document.createElement("a");
          a.href = url;
          a.download = "业务订单.xlsx";
          a.click();
          // 5.释放这个临时的对象url
          window.URL.revokeObjectURL(url);
        });
    },
    // 操作中的查看
    handleClickWatch(row) {
      this.pictureIn = {};
      this.pictureOut = {};
      this.pictureClear = {};
      this.insrcList = [];
      this.outsrcList = [];
      this.clearsrcList = [];
      this.activities = [];

      this.watchFormList = row;
      let info = {
        orderSequence: row.orderSequence,
      };
      // 支付时序图
      this.$businessOrders.getPayAndRefundRecordMap(info).then((res) => {
        res.resultEntity.forEach((item) => {
          this.activities.push({
            content: item.description,
            timestamp: item.time,
          });
        });
      });
      // 重新计费
      this.$commonAxios.charge(info).then((res) => {
        this.watchFormList.parkDurationFmt = parkStopTime(
          new Date().Format("yyyy-MM-dd hh:mm:ss"),
          res.resultEntity.entranceTime
        );

        this.watchFormList.receivedMoneyAmountFmt = Number(
          Number(res.resultEntity.receivedMoneyAmount) / 100
        ).toFixed(2);
        this.watchFormList.receivableMoneyAmountFmt = Number(
          Number(res.resultEntity.receivableMoneyAmount) / 100
        ).toFixed(2);
      });
      // 获取取证图片
      this.$queryDict.getEvidenceByOrderSequence(info).then((res) => {
        if (res.resultEntity.length == 0) {
          this.$error;
        } else if (res.resultEntity.length !== 0) {
          res.resultEntity.forEach((item) => {
            if (item.eventType === 1) {
              this.pictureIn = item;
              this.insrcList.push(
                this.$imgBaseUrl + this.pictureIn.evidencePic
              );
            } else if (item.eventType === 2) {
              this.pictureClear = item;
              this.clearsrcList.push(
                this.$imgBaseUrl + this.pictureClear.evidencePic
              );
            } else if (item.eventType === 3) {
              this.pictureOut = item;
              this.outsrcList.push(
                this.$imgBaseUrl + this.pictureOut.evidencePic
              );
            }
          });
        }
      });
      this.watchListDialog = true;
    },
    // 改单中的颜色发生变化
    handleChangePlateColor(val) {
      this.alterColor = this.colorList.filter((item) => {
        if (item.code == val) {
          return item;
        }
      });
    },
    // 操作中的改单
    handleClickUpdate(row) {
      this.editForm = row;
      this.parkSpaceNumber = this.editForm.parkSpaceNumber;
      this.dialogFormVisible = true;
    },
    //修改表单弹出框提交事件
    formSubmit() {
      if (this.alterColor.length !== 0) {
        this.editForm.numberPlateColorCode = Number(this.alterColor[0].code);
        this.editForm.plateColor = this.alterColor[0].name;
      }
      let that = this;
      let editInfo = {
        orderSequence: this.editForm.orderSequence,
        plateNumber: this.editForm.plateNumber,
        numberPlateColorCode: this.editForm.numberPlateColorCode,
        parkSpaceNumber: this.editForm.parkSpaceNumber,
      };
      if (this.editForm.parkSpaceNumber === this.parkSpaceNumber) {
        that.$commonAxios.carParkEditOrder(editInfo).then(() => {
          that.$message({
            showClose: true,
            message: "修改成功",
            type: "success",
          });
          that.dialogFormVisible = false;
          that.queryTableList();
        });
      } else {
        const info = {
          parkSpaceNumber: this.editForm.parkSpaceNumber,
        };
        that.$businessOrders.queryOrderOnlineByParkSpaceId(info).then((res) => {
          if (!res.resultEntity) {
            that.$commonAxios.carParkEditOrder(editInfo).then(() => {
              that.$message({
                showClose: true,
                message: "修改成功",
                type: "success",
              });
              that.dialogFormVisible = false;
              that.queryTableList();
            });
          } else {
            that.$message.warning("该泊位已存在订单！");
          }
        });
      }
    },
    // 车牌颜色下拉
    getColorDict() {
      let info = {
        columnName: ["value", "name"],
        tableName: "tb_dict",
        whereStr: [
          {
            colName: "parent_id",
            value: "204E657017184ADFAE6E39629B38DDC2",
          },
        ],
      };
      this.$queryDict.queryDict(info).then((response) => {
        this.colorList = response.resultEntity;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
$topSelectHeight: 185px;
.mainbody {
  width: 100%;
  height: 100%;
}
.tableRow {
  width: 100%;
  @include background("blockBg");
  margin-top: 16px;
  padding-left: 20.2px;
  padding-right: 20.2px;
  padding-top: 5px;
  .tableContent {
    margin-top: 10px;
  }
}
.span-title {
  color: #79baf7;
  font-size: 16px;
}
.span-text {
  color: #789db7;
  font-size: 16px;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}
/deep/ .el-timeline-item__content {
  color: #ffffff !important;
}
/deep/ .el-timeline-item__timestamp.is-bottom {
  color: #ffffff !important;
}
.abnormalLine {
  width: 100%;
  height: 300px;
  .itemCol {
    margin-top: 20px;
    text-align: center;
    @include font_color(appFontColor);
    .itemUpload {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px solid gray;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
