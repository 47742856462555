<!--
 * @Description: 车行道停车运营管理 运营管理 业务订单 businessOrders
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class='mainbody'>
    <el-row class="topSelectLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label="订单编号"
                          label-width="90px">
              <el-input v-model="searchForm.orderSequence"
                        placeholder="订单编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="停车场名称"
                          label-width="90px">
              <el-select class="search-input"
                         clearable
                         filterable
                         v-model="searchForm.parkId"
                         placeholder="停车场名称">
                <el-option v-for="item in parkNameList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="车牌号"
                          label-width="90px">
              <el-input v-model="searchForm.plateNumber"
                        placeholder="车牌号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="泊位号"
                          label-width="110px">
              <el-input v-model="searchForm.parkSpaceNumber"
                        placeholder="泊位号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topSecondLine">
          <el-col :span="8">
            <el-form-item label-width="90px"
                          label="进场时间">
              <el-date-picker v-model="entranceTime"
                              type="datetimerange"
                              align="right"
                              unlink-panels
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出场时间"
                          label-width="90px">
              <el-date-picker v-model="leaveTime"
                              type="datetimerange"
                              align="right"
                              unlink-panels
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="最后缴费时间"
                          label-width="110px">
              <el-date-picker v-model="lastPaymentTime"
                              type="datetimerange"
                              align="right"
                              unlink-panels
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topSecondLine">
          <el-col :span="6">
            <el-form-item label="订单状态"
                          label-width="90px">
              <el-select class="search-input"
                         clearable
                         filterable
                         v-model="searchForm.orderStatusCode"
                         placeholder="订单状态">
                <el-option v-for="item in orderStatusCodeList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row class="topButton">
        <el-button type="primary"
                   @click="queryButton">查 询</el-button>
        <el-button type="info"
                   @click="resetForm"
                   style="margin-left:17px;">重 置</el-button>
      </el-row>
    </el-row>
    <!-- tab页 -->
    <el-row class="contentTitle">
      <el-tabs class="tabsContent"
               v-model="activeName">
        <el-tab-pane label="实时订单列表"
                     name="first"></el-tab-pane>
        <el-tab-pane label="历史订单列表"
                     name="second"></el-tab-pane>
      </el-tabs>
      <el-row class="tableTitle">
        <el-button type="info"
                   class="tableTitleButton"
                   style="margin-left:20px"
                   @click="exportFile">导 出</el-button>
        <el-button type="info"
                   class="tableTitleButton"
                   @click="queryButton">刷 新</el-button>
      </el-row>
    </el-row>
    <!-- 实时订单 -->
    <el-row v-if="activeName ==='first'">
      <online ref="businessOrdersRef"
              :searchForm="searchForm" />
    </el-row>
    <!-- 历史订单 -->
    <el-row v-if="activeName==='second'">
      <history ref="businessOrdersRef"
               :searchForm="searchForm" />
    </el-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import online from './online.vue'
import history from './history.vue'

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    online, history
  },
  data () {
    // 这里存放数据
    return {
      activeName: 'first',

      searchForm: {},

      entranceTime: [],
      leaveTime: [],
      lastPaymentTime: [],

      parkNameList: [],// 停车场名称
      orderStatusCodeList: [], // 订单状态列表

      // 时间选择器
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    entranceTime (val) {
      if (val) {
        this.searchForm.entranceStartTime = val[0]
        this.searchForm.entranceEndTime = val[1]
      } else {
        this.searchForm.entranceStartTime = ''
        this.searchForm.entranceEndTime = ''
      }
    },
    leaveTime (val) {
      if (val) {
        this.searchForm.leaveStartTime = val[0]
        this.searchForm.leaveEndTime = val[1]
      } else {
        this.searchForm.leaveStartTime = ''
        this.searchForm.leaveEndTime = ''
      }
    },
    lastPaymentTime (val) {
      if (val) {
        this.searchForm.lastPaymentStartTime = val[0]
        this.searchForm.lastPaymentEndTime = val[1]
      } else {
        this.searchForm.lastPaymentStartTime = ''
        this.searchForm.lastPaymentEndTime = ''
      }
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryOrderStatusCodeList()
    this.queryParkNameList()
  },
  // 方法集合
  methods: {
    // 查询按钮
    queryButton () {
      this.$refs.businessOrdersRef.queryButton()
    },
    resetForm () {
      this.entranceTime = []
      this.leaveTime = []
      this.lastPaymentTime = []
      this.searchForm = {}
      this.$refs.businessOrdersRef.resetForm()
    },
    // 订单状态下拉
    queryOrderStatusCodeList () {
      const info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: 'F019BB8F5FAF440680FEBCE119B49622',
          },
        ],
      }
      this.$queryDict.queryDict(info).then((res) => {
        this.orderStatusCodeList = res.resultEntity
      })
    },
    // 查询停车场
    queryParkNameList () {
      let info = {
        "columnName": ["park_id", "park_name"],
        "tableName": "tb_park",
        "whereStr": [
          {
            "colName": "park_type_code",
            "value": 1
          }
        ]
      }
      this.$queryDict.queryDict(info).then(res => {
        this.parkNameList = res.resultEntity
      })
    },
    // 导出
    exportFile () {
      this.$axios.post(this.$downloadBaseUrl + "/order/downloadOrderIn", this.searchForm, {
        responseType: "blob",
        headers: {
          'userToken': localStorage.getItem("userToken")
        },
      }
      ).then(res => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = "业务订单.xlsx";
        a.click();
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(url);
      })
    },
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
$topSelectHeight: 235px;
.mainbody {
  height: 100%;
  .topSelectLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topFirstLine {
    }
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .contentTitle {
    height: 32px;
    margin-bottom: 10px;
    margin-top: 20px;
    display: flex;
    .tabsContent {
      width: 90%;
    }
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      margin-right: 20px;
      display: flex;
      .tableTitleButton {
        float: right;
      }
    }
  }
}
</style>